import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, forkJoin, last, map, mergeMap, of, switchMap, take } from 'rxjs';
import { INNodeModel } from 'src/app/@core/model/asset.model';
import { AssetStateService } from 'src/app/@core/services/asset-state.service';
import { TimeseriesService } from 'src/app/timeseries/services/timeseries.service';
import { AssetsActionTypeNames, assetsActionTypes } from '../actions/asset.actions';
import { selectSelectedAsset } from '../selectors/assets.selectors';

@Injectable()
export class AssetStateEffects {
  loadStatesForSelectedAsset = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActionTypeNames.SET_SELECTED_ASSET, AssetsActionTypeNames.UPDATE_ASSET),
      mergeMap((action: { asset: INNodeModel }) => {
        // only if uuid is set and different to previously selected asset
        if (action?.asset?.uuid) { // && action?.asset?.uuid !== previouslySelectedAsset?.uuid) {
          return forkJoin([
            this.timeseriesService.getLatestChangeValueForProperty(action?.asset?.uuid, 'SDT.AGG.CS'),
            this.timeseriesService.getLatestChangeValueForProperty(action?.asset?.uuid, 'SDT.SystemStatus.TDt')
          ]).pipe(
            last(),
            mergeMap(([conditionState, connectionState]: [any, any]) => {
              return this.store.select(selectSelectedAsset).pipe(take(1), map((selectedAsset: INNodeModel) => {
                const assetWithStates = Object.assign({}, selectedAsset);

                assetWithStates['conditionState'] = {
                  value: conditionState?.v,
                  labelKey: this.assetStateService.getStateLabelKey(conditionState?.v),
                  hexColor: this.assetStateService.getStateHexColor(conditionState?.v),
                  icon: this.assetStateService.getStateIcon(conditionState?.v),
                  timestamp: conditionState?.ts
                };
                assetWithStates['connectionState'] = {
                  value: connectionState?.v,
                  label: this.assetStateService.getStateLabelKey(connectionState?.v),
                  timestamp: connectionState?.ts
                };

                return assetsActionTypes.updateSelectedAsset({ asset: assetWithStates });
              }))
            }),
            catchError((error: any) =>
              this.store.select(selectSelectedAsset).pipe(map((selectedAsset: INNodeModel) =>
                assetsActionTypes.updateSelectedAsset({ asset: this.resetAssetState(selectedAsset) })
              ))
            )
          )
        } else {
          return this.store.select(selectSelectedAsset).pipe(take(1), switchMap((selectedAsset: INNodeModel) =>
            of(assetsActionTypes.updateSelectedAsset({ asset: this.resetAssetState(selectedAsset) }))
          ));
        }
      }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private timeseriesService: TimeseriesService,
    private assetStateService: AssetStateService
  ) { }

  private resetAssetState(asset: INNodeModel): INNodeModel {
    const assetWithoutStates = Object.assign({}, asset);

    assetWithoutStates['conditionState'] = {
      value: 0,
      labelKey: this.assetStateService.getStateLabelKey(0),
      hexColor: this.assetStateService.getStateHexColor(0),
      icon: this.assetStateService.getStateIcon(0),
      timestamp: undefined
    };
    assetWithoutStates['connectionState'] = {
      value: 12,
      label: this.assetStateService.getStateLabelKey(12),
      timestamp: undefined
    };

    return assetWithoutStates;
  }
}
