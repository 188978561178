import { ChartData } from 'chart.js';

export class CustomChartData {
  id: string;
  propertyPath: string;
  data: ChartData;

  constructor(id: string, propertyPath: string, data: ChartData) {
    this.id = id;
    this.propertyPath = propertyPath;
    this.data = data;
  }
}
