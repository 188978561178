import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, GuardResult, MaybeAsync, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChmGuard implements CanActivate {
  constructor(private router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): MaybeAsync<GuardResult> {
    if (window.env.region !== 'cn' && state.url.split('/').includes('central-hierarchy-management') ||
      window.env.region === 'cn' && state.url.split('/').includes('hierarchy-management')){
      return of(true)
    } else {
      return of(this.router.createUrlTree(['home']));
    }
  }

}
