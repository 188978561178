import { createAction, props } from '@ngrx/store';
import { ChartOptions } from 'chart.js';

export enum ChartOptionsActionTypeNames {
  CREATE_DEFAULT_CHART_OPTIONS = '[ChartOptions] Create default chart options',
  CREATE_DEFAULT_CHART_OPTIONS_SUCCESS = '[ChartOptions] Create default chart options success',
  CREATE_DEFAULT_CHART_OPTIONS_FAIL = '[ChartOptions] Create default chart options fail',
  CREATE_CHART_OPTIONS_FOR_PROPERTY = '[ChartOptions] Create chart options for property',
  CREATE_CHART_OPTIONS_FOR_PROPERTY_SUCCESS = '[ChartOptions] Create chart options for property success',
  CREATE_CHART_OPTIONS_FOR_PROPERTY_FAIL = '[ChartOptions] Create chart options for property fail',

  UPDATE_CHART_OPTIONS = '[ChartOptions] Update chart options',
  UPDATE_CHART_OPTIONS_SUCCESS = '[ChartOptions] Update chart options success',
  UPDATE_CHART_OPTIONS_FAIL = '[ChartOptions] Update chart options fail',
  UPDATE_CHART_OPTIONS_FOR_PROPERTY = '[ChartOptions] Update chart options for property',
  UPDATE_CHART_OPTIONS_FOR_PROPERTY_SUCCESS = '[ChartOptions] Update chart options for property success',
  UPDATE_CHART_OPTIONS_FOR_PROPERTY_FAIL = '[ChartOptions] Update chart options for property fail',

  DELETE_CHART_OPTIONS_FOR_PROPERTY = '[ChartOptions] Delete chart options for property',
  DELETE_CHART_OPTIONS_FOR_PROPERTY_SUCCESS = '[ChartOptions] Delete chart options for property success',
  DELETE_CHART_OPTIONS_FOR_PROPERTY_FAIL = '[ChartOptions] Delete chart options for property fail',

  RESET = '[ChartOptions] Restore initial state',
}

export const createChartOptions = createAction(
  `${ChartOptionsActionTypeNames.CREATE_DEFAULT_CHART_OPTIONS}`,
  props<{
    chartId: string;
    options: ChartOptions;
  }>()
);

export const createChartOptionsSuccess = createAction(
  `${ChartOptionsActionTypeNames.CREATE_DEFAULT_CHART_OPTIONS_SUCCESS}`,
  props<{
    chartId: string;
    propertyPath: string;
    options: ChartOptions;
  }>()
);

export const createChartOptionsFail = createAction(
  `${ChartOptionsActionTypeNames.CREATE_DEFAULT_CHART_OPTIONS_FAIL}`,
  props<{
    chartId: string;
    propertyPath: string;
    options: ChartOptions;
  }>()
);

export const updateChartOptions = createAction(
  `${ChartOptionsActionTypeNames.UPDATE_CHART_OPTIONS}`,
  props<{
    chartId: string;
    propertyPath: string;
    options: ChartOptions;
  }>()
);

export const updateChartOptionsSuccess = createAction(
  `${ChartOptionsActionTypeNames.UPDATE_CHART_OPTIONS_SUCCESS}`,
  props<{
    chartId: string;
    propertyPath: string;
    options: ChartOptions;
  }>()
);

export const updateChartOptionsFail = createAction(
  `${ChartOptionsActionTypeNames.UPDATE_CHART_OPTIONS_FAIL}`,
  props<{
    chartId: string;
    propertyPath: string;
    options: ChartOptions;
  }>()
);

export const createChartOptionsForProperty = createAction(
  `${ChartOptionsActionTypeNames.CREATE_CHART_OPTIONS_FOR_PROPERTY}`,
  props<{
    chartId: string;
    propertyPath: string;
    options: ChartOptions;
  }>()
);

export const createChartOptionsForPropertySuccess = createAction(
  `${ChartOptionsActionTypeNames.CREATE_CHART_OPTIONS_FOR_PROPERTY_SUCCESS}`,
  props<{
    chartId: string;
    propertyPath: string;
    options: ChartOptions;
  }>()
);

export const createChartOptionsForPropertyFail = createAction(
  `${ChartOptionsActionTypeNames.CREATE_CHART_OPTIONS_FOR_PROPERTY_FAIL}`,
  props<{
    chartId: string;
    propertyPath: string;
    options: ChartOptions;
  }>()
);

export const updateChartOptionsForProperty = createAction(
  `${ChartOptionsActionTypeNames.UPDATE_CHART_OPTIONS_FOR_PROPERTY}`,
  props<{
    chartId: string;
    options: ChartOptions;
  }>()
);

export const updateChartOptionsForPropertySuccess = createAction(
  `${ChartOptionsActionTypeNames.UPDATE_CHART_OPTIONS_FOR_PROPERTY_SUCCESS}`,
  props<{
    chartId: string;
    propertyPath: string;
    options: ChartOptions;
  }>()
);

export const updateChartOptionsForPropertyFail = createAction(
  `${ChartOptionsActionTypeNames.UPDATE_CHART_OPTIONS_FOR_PROPERTY_FAIL}`,
  props<{
    chartId: string;
    propertyPath: string;
    options: ChartOptions;
  }>()
);

export const deleteChartOptionsForProperty = createAction(
  `${ChartOptionsActionTypeNames.DELETE_CHART_OPTIONS_FOR_PROPERTY}`,
  props<{
    chartId: string;
    propertyPath: string;
  }>()
);

export const deleteChartOptionsForPropertySuccess = createAction(
  `${ChartOptionsActionTypeNames.DELETE_CHART_OPTIONS_FOR_PROPERTY_SUCCESS}`,
  props<{
    chartId: string;
    propertyPath: string;
  }>()
);

export const deleteChartOptionsForPropertyFail = createAction(
  `${ChartOptionsActionTypeNames.DELETE_CHART_OPTIONS_FOR_PROPERTY_FAIL}`,
  props<{
    chartId: string;
    propertyPath: string;
  }>()
);

export const reset = createAction(`${ChartOptionsActionTypeNames.RESET}`);

export const chartOptionsActionTypes = {
  createChartOptions,
  createChartOptionsSuccess,
  createChartOptionsFail,
  createChartOptionsForProperty,
  createChartOptionsForPropertySuccess,
  createChartOptionsForPropertyFail,
  updateChartOptions,
  updateChartOptionsSuccess,
  updateChartOptionsFail,
  updateChartOptionsForProperty,
  updateChartOptionsForPropertySuccess,
  updateChartOptionsForPropertyFail,
  deleteChartOptionsForProperty,
  deleteChartOptionsForPropertySuccess,
  deleteChartOptionsForPropertyFail,
  reset,
};
