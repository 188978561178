// Import the core angular services.
import { ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Event as RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Params } from '@angular/router';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoutingParameterService {
  params: BehaviorSubject<Params>;
  paramsSnapshot: Params = {};

  constructor(router: Router) {
    this.params = new BehaviorSubject(this.paramsSnapshot);
    router.events
      .pipe(
        filter(
          // only at navigation end check for params
          (event: RouterEvent): boolean => {
            return event instanceof NavigationEnd;
          }
        )
      )
      .subscribe((): void => {
        const snapshot = router.routerState.snapshot.root;
        const nextParams = this.getRouteParams(snapshot);
        this.params.next((this.paramsSnapshot = nextParams));
      });
  }

  private getRouteParams(root: ActivatedRouteSnapshot): Params {
    const params: Params = {};
    this.mergeParams(root, params);
    return params;
  }

  private mergeParams(snapshot: ActivatedRouteSnapshot, params: Params): void {
    Object.assign(params, snapshot.params);
    snapshot.children.forEach((snap) => this.mergeParams(snap, params));
  }

  private paramsChanged(currentParams: Params, nextParams: Params): boolean {
    const currentKeys = Object.keys(currentParams);
    const nextKeys = Object.keys(nextParams);
    return currentKeys.length !== nextKeys.length || currentKeys.some((key) => currentParams[key] !== nextParams[key]);
  }
}
