import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, last, map, mergeMap, of, take } from 'rxjs';
import { INNodeModel } from 'src/app/@core/model/asset.model';
import { FeatureService } from 'src/app/@shared/services/feature.service';
import { AssetsActionTypeNames, assetsActionTypes } from '../actions/asset.actions';
import { selectSelectedAsset } from '../selectors/assets.selectors';


@Injectable()
export class FeaturesEffects {
  loadFeaturesForSelectedAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActionTypeNames.SET_SELECTED_ASSET, AssetsActionTypeNames.UPDATE_ASSET),
      mergeMap((args: { asset: INNodeModel }) => {
        if (args?.asset?.uuid) {
          return this.service.getFeatures(args?.asset)
            .pipe(
              last(),
              mergeMap((features: any) => {
                return this.store.select(selectSelectedAsset).pipe(take(1), map((selectedAsset: INNodeModel) => {
                  const assetWithFeatures = Object.assign({}, selectedAsset);
                  assetWithFeatures.features = features;
                  return assetsActionTypes.updateSelectedAsset({ asset: assetWithFeatures });
                }))
              }),
              catchError((error: any) =>
                this.store.select(selectSelectedAsset).pipe(map((selectedAsset: INNodeModel) => {
                  const assetWithFeatures = Object.assign({}, selectedAsset);
                  assetWithFeatures.features = undefined;
                  return assetsActionTypes.updateSelectedAsset({ asset: assetWithFeatures });
                }))
              )
            )
        } else {
          return of(assetsActionTypes.noSelectedAssetUpdate());
        }
      })
    ),// { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: FeatureService,
    private store: Store
  ) { }
}
