import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CustomChartData } from 'src/app/charts/models/chart-data.model';
import { chartDataActionTypes } from '../actions/chart-data.actions';

export const ChartDataFeatureKey = 'chartData';

export interface ChartDataState extends EntityState<CustomChartData> {
  loading: boolean;
  error: string;
}

export const adapter: EntityAdapter<CustomChartData> = createEntityAdapter<CustomChartData>({
  selectId: (data) => data.id + data.propertyPath
});

export const initialState = adapter.getInitialState({
  loading: true,
  error: '',
});

export const reducer = createReducer(
  initialState,
  on(chartDataActionTypes.createData, (state, action) => {
    return {
      ...adapter.upsertOne(new CustomChartData(
        action.chartId,
        action.propertyPath,
        action.data
      ), state),
      loading: false,
      error: '',
    };
  }),
  on(chartDataActionTypes.updateData, (state, action) => {
    return {
      ...adapter.updateOne(
        {
          id: action.chartId,
          changes: new CustomChartData(
            action.chartId,
            action.propertyPath,
            action.data
          )
        }, state),
      loading: false,
      error: '',
    };
  }),
  on(chartDataActionTypes.deleteData, (state, action) => {
    return {
      ...adapter.removeOne(action.chartId + action.propertyPath, state),
      loading: false,
      error: '',
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const isChartDataLoading = (state: ChartDataState): boolean => state.loading;
export const getError = (state: ChartDataState): string => state.error;
