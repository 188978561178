import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withHashLocation } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbOverlayModule,
  NbSidebarModule,
  NbThemeModule,
  NbTimepickerModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { MissingTranslationHandler, provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatomoModule, MatomoRouterModule } from 'ngx-matomo-client';
import { IqMissingTranslationHandler } from './@core/classes/iq-missing-translation-handler';
import { INNOMOTICS_DARK_THEME, INNOMOTICS_LIGHT_THEME } from './@theme/styles/theme.innomotics';
import { ThemeModule } from './@theme/theme.module';
import { appRoutes } from './app-routing.module';
import * as fromChartConfiguration from './charts/+store/reducers/chart-configuration.reducer';
import * as fromChartData from './charts/+store/reducers/chart-data.reducer';
import * as fromChartOptions from './charts/+store/reducers/chart-options.reducer';
import { effects } from './store/effects';
import * as fromAssets from './store/reducers/assets.reducer';
import * as fromCustomProperties from './store/reducers/custom-properties.reducer';
import * as fromLoading from './store/reducers/loading.reducer';
import * as fromNodes from './store/reducers/nodes.reducer';
import * as fromProperties from './store/reducers/properties.reducer';
import * as fromSpectrumChartMulti from './store/reducers/spectrum-chart-multi.reducer';
import * as fromSpectrum from './store/reducers/spectrum.reducer';
import * as fromSubsets from './store/reducers/subsets.reducer';
import * as fromTime from './store/reducers/time.reducer';
import * as fromTimeseriesChartMulti from './store/reducers/timeseries-chart-multi.reducer';
import * as fromTimeseries from './store/reducers/timeseries.reducer';
import * as fromUserAssets from './store/reducers/user-assets.reducer';
import * as fromUserProperties from './store/reducers/user-properties.reducer';

import {
  AppState as IQCreateAppState,
  AuthService as IQCreateAuthService,
  ClientService as IQCreateClientService,
  LANG as IQCreateLang,
  LocaleService as IQCreateLocaleService,
  NotificationService as IQCreateNotificationService,
  TimePeriod as IQCreateTimePeriod,
  TimeService as IQCreateTimeService,
  TimeState as IQCreateTimeState,
  TimePeriod,
} from '@inspire-iq/iq-create';
import { ClientService } from './@core/services/client.service';
import { NotificationService } from './@core/services/notification.service';
import { LANG } from './@shared/services/language-keys';
import { LocaleService } from './@shared/services/locale.service';
import { TimeService } from './@shared/services/time.service';
import { AppState } from './store';
import { TimeState } from './store/reducers/time.reducer';


export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: Window, useValue: window },
    { provide: APP_BASE_HREF, useValue: window['base-href'] },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    provideAnimations(),
    provideRouter(appRoutes, withHashLocation()),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: IqMissingTranslationHandler,
      },
      defaultLanguage: 'en',
    }),
    provideStore({
      chartConfiguration: fromChartConfiguration.reducer,
      chartOptions: fromChartOptions.reducer,
      chartData: fromChartData.reducer,
      assets: fromAssets.assetsReducer,
      nodes: fromNodes.nodesReducer,
      selectedAsset: fromAssets.selectedAssetReducer,
      selectedAssetProperties: fromProperties.selectedAssetPropertiesReducer,
      selectedMasterAssetProperties: fromProperties.selectedMasterAssetPropertiesReducer,
      userAssets: fromUserAssets.reducer,
      userProperties: fromUserProperties.reducer,
      timeseries: fromTimeseries.reducer,
      spectrum: fromSpectrum.reducer,
      properties: fromProperties.reducer,
      customProperties: fromCustomProperties.reducer,
      subsets: fromSubsets.reducer,
      timeseriesChartMulti: fromTimeseriesChartMulti.reducer,
      spectrumChartMulti: fromSpectrumChartMulti.reducer,
      time: fromTime.reducer,
      loading: fromLoading.reducer,
    }),
    provideStoreDevtools({
      maxAge: 25,
      connectInZone: true,
    }),
    provideEffects(effects),
    importProvidersFrom(
      AuthModule.forRoot({
        domain: window?.env?.domain,
        clientId: window?.env?.clientID,
        httpInterceptor: {
          allowedList: [
            {
              uriMatcher: (uri): boolean => uri.includes('assets/i18n'),
              allowAnonymous: true,
            },
            window?.env?.stageUrl + '/*',
            window?.env?.centralAssetManagement + '*',
          ],
        },
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        errorPath: '/authentication-problem',
        authorizationParams: {
          audience: window?.env?.audience,
          redirect_uri: window.location.origin + window.location.pathname,
          display: window?.env?.display,
          connection: window?.env?.connection,
          acr_values: 'aal2',
          prompt: 'login',
          scope: window?.env?.scope,
        },
      }),
      JwtModule.forRoot({
        config: {
          tokenGetter: () => undefined,
          allowedDomains: ['siemens.com'],
        },
      }),
      MatomoModule.forRoot({
        scriptUrl: window?.env?.matomoUrl + 'matomo.js',
        trackers: [
          {
            trackerUrl: window?.env?.matomoUrl + 'matomo.php',
            siteId: window?.env?.matomoSiteId,
          },
        ],
        enableLinkTracking: true,
      }),
      MatomoRouterModule,
      NbThemeModule.forRoot(
        {
          // name: 'siemens',
          // name: 'innomotics-dark',
          name: 'innomotics-light',
        },
        [INNOMOTICS_LIGHT_THEME, INNOMOTICS_DARK_THEME],
      ),
      NbToastrModule.forRoot(),
      NbSidebarModule.forRoot(),
      NbMenuModule.forRoot(),
      NbDialogModule.forRoot(),
      NbTimepickerModule.forRoot(),
      NbDatepickerModule.forRoot(),
      NbWindowModule.forRoot(),
      NbOverlayModule.forRoot(),
      ThemeModule,
      BrowserAnimationsModule,
      BrowserModule,
      CommonModule,
    ),

    { provide: IQCreateTimeService, useExisting: TimeService },
    { provide: IQCreateClientService, useExisting: ClientService },
    { provide: IQCreateNotificationService, useExisting: NotificationService },
    { provide: IQCreateLocaleService, useExisting: LocaleService },
    { provide: IQCreateAppState, useExisting: AppState },
    { provide: IQCreateTimeState, useExisting: TimeState },
    { provide: IQCreateTimePeriod, useExisting: TimePeriod },
    { provide: IQCreateAuthService, useExisting: AuthService },
    { provide: IQCreateLang, useExisting: LANG }

  ],
};