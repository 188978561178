import { ChartOptions } from 'chart.js';

export class ChartOptionsWrapper {
  chartId: string;
  propertyPath?: string;
  options?: ChartOptions;

  constructor(chartId: string, propertyPath?: string, options?: ChartOptions) {
    this.chartId = chartId;
    this.propertyPath = propertyPath;
    this.options = options;
  }
}
