import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, last, map, mergeMap, takeUntil } from 'rxjs/operators';
import { INNodeModel } from 'src/app/@core/model/asset.model';
import { Feature } from 'src/app/timeseries/model/feature.model';
import { Property } from 'src/app/timeseries/model/property.model';
import { PropertiesService } from 'src/app/timeseries/services/properties.service';
import { AssetsActionTypeNames } from '../actions/asset.actions';
import { PropertiesActionTypeNames, propertiesActionTypes } from '../actions/properties.actions';

@Injectable()
export class PropertiesEffects {
  loadPropertiesInitial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActionTypeNames.LOAD_PROPERTIES_INITIAL),
      mergeMap((args: any) =>
        combineLatest([
          this.service.getProperties(args.uuid, args.dataTypes, args.subsetId, args.filter, undefined, args.limit, args.units),
          this.service.getUnitsFor(args.assettypeId, args.subsetId, args.dataTypes)])
          .pipe(
            map(([properties, units]) => {
              const unitsAsFeautures: Array<Feature> = units.map(unit => new Feature(unit));
              return propertiesActionTypes.loadPropertiesSuccess(
                {
                  chartId: args.chartId,
                  uuid: args.uuid,
                  properties,
                  units: unitsAsFeautures,
                  subsetId: args.subsetId,
                  cursor: properties.cursor
                }
              )
            }
            ),
            catchError((error: any) =>
              of(propertiesActionTypes.loadPropertiesFail(
                { chartId: args.chartId, uuid: args.uuid, subsetId: args.subsetId, payload: error }
              ))
            )
          )
      )
    )
  );
  loadPropertiesInitialWithoutRedloadingUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActionTypeNames.LOAD_PROPERTIES_INITIAL_WITHOUT_UNITS),
      mergeMap((args: any) =>
        this.service.getProperties(args.uuid, args.dataTypes, args.subsetId, args.filter, undefined, args.limit, args.units)
          .pipe(
            takeUntil(this.actions$.pipe(ofType(PropertiesActionTypeNames.CANCEL_REQUEST))),
            map((properties) => {
              return propertiesActionTypes.loadPropertiesSuccess(
                {
                  chartId: args.chartId,
                  uuid: args.uuid,
                  properties,
                  units: args.units,
                  subsetId: args.subsetId,
                  cursor: properties.cursor
                }
              )
            }
            ),
            catchError((error: any) =>
              of(propertiesActionTypes.loadPropertiesFail(
                { chartId: args.chartId, uuid: args.uuid, subsetId: args.subsetId, payload: error }
              ))
            )
          )
      )
    )
  );
  loadPropertiesNext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActionTypeNames.LOAD_PROPERTIES_NEXT),
      mergeMap((args: any) =>
        this.service.getProperties(args.uuid, args.dataTypes, args.subsetId, args.filter, args.cursor, args.limit, args.units)
          .pipe(
            map((properties) =>
              propertiesActionTypes.loadPropertiesNextSuccess(
                { chartId: args.chartId, uuid: args.uuid, properties, units: undefined, subsetId: args.subsetId, cursor: properties.cursor }
              )
            ),
            catchError((error: any) =>
              of(propertiesActionTypes.loadPropertiesFail(
                { chartId: args.chartId, uuid: args.uuid, subsetId: args.subsetId, payload: error }
              ))
            )
          )
      )
    )
  );

  loadSubsets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActionTypeNames.LOAD_SUBSETS),
      mergeMap((args: any) =>
        this.service.getSubsetsFor(args.uuid).pipe(
          map((data: any) =>
            propertiesActionTypes.loadSubsetsSuccess(
              { chartId: args.chartId, uuid: args.uuid, payload: data }
            )
          ),
          catchError((error: any) =>
            of(propertiesActionTypes.loadSubsetsFail(
              { chartId: args.chartId, uuid: args.uuid, payload: error }
            ))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private service: PropertiesService) { }
}

@Injectable()
export class SelectedAssetPropertiesEffects {
  loadAllPropertiesForSelectedAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActionTypeNames.SET_SELECTED_ASSET),
      mergeMap((args: { asset: INNodeModel }) => {
        if (args?.asset?.uuid) {
          return this.service.getAllProperties(args?.asset?.uuid)
            .pipe(
              last(),
              map((properties: Array<Property>) => {
                return propertiesActionTypes.loadPropertiesForSelectedAssetSuccess({ properties })
              }),
              catchError((error: any) =>
                of(propertiesActionTypes.loadPropertiesForSelectedAssetFail(
                  { payload: error }
                ))
              )
            )
        } else {
          return of(propertiesActionTypes.resetSelectedAssetProperties());
        }
      })
    )
  );

  loadAllPropertiesForSelectedMasterAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActionTypeNames.SET_SELECTED_ASSET),
      mergeMap((args: { asset: INNodeModel }) => {
        if (args?.asset?.masterId && args?.asset?.masterId !== args?.asset?.uuid) {
          return this.service.getAllProperties(args.asset.masterId)
            .pipe(
              last(),
              map((properties: Array<Property>) => {
                return propertiesActionTypes.loadPropertiesForSelectedMasterAssetSuccess({ properties })
              }),
              catchError((error: any) =>
                of(propertiesActionTypes.loadPropertiesForSelectedMasterAssetFail({ payload: error }))
              )
            );
        } else {
          return of(propertiesActionTypes.resetSelectedMasterAssetProperties());
        }
      })
    )
  );

  constructor(private actions$: Actions, private service: PropertiesService) { }
}
