import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { combineLatest, finalize, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, take } from 'rxjs/operators';
import { FingerprintService } from 'src/app/@core/services/fingerprint.service';
import {
  SpectraRequest,
  StateHistogramRequest,
  TimeseriesAccumulatedRequest,
  TimeseriesRequest,
  TimeseriesResolutionRequest
} from 'src/app/timeseries/model/timeseries-request';
import { TimeseriesService } from '../../timeseries/services/timeseries.service';
import { loadingActionTypes } from '../actions/loading.actions';
import {
  loadDrivestateFail,
  loadDrivestateSuccess,
  loadTimeseriesAccumulatedFail,
  loadTimeseriesAccumulatedSuccess,
  loadTimeseriesChangesSuccess,
  loadTimeseriesFail,
  loadTimeseriesRecentFail,
  loadTimeseriesRecentSuccess,
  loadTimeseriesResolutionSuccess,
  loadTimeseriesSpectrumFail,
  loadTimeseriesSpectrumSuccess,
  loadTimeseriesSuccess,
  TimeseriesActionTypeNames
} from '../actions/timeseries.actions';

@Injectable()
export class TimeseriesEffects {
  loadDrivestate$ = createEffect(() => this.actions$.pipe(
    ofType(TimeseriesActionTypeNames.LOAD_DRIVESTATE),
    exhaustMap((args: StateHistogramRequest) => {
      this.store.dispatch(loadingActionTypes.load({
        categoryId: args.loadingCategoryId ?? 'timeseries',
        subcategoryId: args.loadingSubcategoryId ?? args.chartId,
        propertyPath: args.property,
      }));

      return this.service.getDriveStateHistogram(args.uuid, args.timeUnit, args.count, args.timestamp)
        .pipe(
          map((data) => loadDrivestateSuccess({
            chartId: args.chartId, uuid: args.uuid, property: args.property, payload: data
          })),
          catchError((error: any) =>
            of(loadDrivestateFail({ chartId: args.chartId, uuid: args.uuid, property: args.property, payload: error.message }))
          ),
          finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
            { categoryId: args.loadingCategoryId ?? 'timeseries', propertyPath: args.property }))
          )
        )
    })
  ));

  loadTimeseriesAccumulated$ = createEffect(() => this.actions$.pipe(
    ofType(TimeseriesActionTypeNames.LOAD_TIMESERIES_ACCUMULATED),
    mergeMap((args: TimeseriesAccumulatedRequest) => {
      this.store.dispatch(loadingActionTypes.load({
        categoryId: args.loadingCategoryId ?? 'timeseries',
        subcategoryId: args.loadingSubcategoryId ?? args.chartId,
        propertyPath: args.property?.path,
        propertyName: args.property?.name
      }));

      return this.service.loadTimeseriesAccumulated(args.uuid, args.property?.path, args.timeUnit, args.count, args.timestamp)
        .pipe(
          map((data) => loadTimeseriesAccumulatedSuccess({
            chartId: args.chartId, uuid: args.uuid, property: args.property,
            timeUnit: args.timeUnit, count: args.count, timestamp: args.timestamp,
            payload: data
          })),
          catchError((error: any) => {
            return of(loadTimeseriesAccumulatedFail(
              { chartId: args.chartId, uuid: args.uuid, property: args.property?.path, payload: error.message }
            ))
          }),
          finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
            { categoryId: args.loadingCategoryId ?? 'timeseries', propertyPath: args.property?.path }))
          )
        )
    })
  ));

  loadTimeseriesRecent$ = createEffect(() => this.actions$.pipe(
    ofType(TimeseriesActionTypeNames.LOAD_TIMESERIES_RECENT),
    mergeMap((args: TimeseriesRequest) => {
      this.store.dispatch(loadingActionTypes.load({
        categoryId: args.loadingCategoryId ?? 'timeseries',
        subcategoryId: args.loadingSubcategoryId ?? args.chartId,
        propertyPath: args.property?.path,
        propertyName: args.property?.name
      }));

      return this.service.getLatestValueForProperty(args.uuid, args.property?.path)
        .pipe(
          map((data) => loadTimeseriesRecentSuccess({
            chartId: args.chartId,
            uuid: args.uuid,
            property: args.property,
            payload: data
          })),
          catchError((error: any) => of(loadTimeseriesRecentFail(
            { chartId: args.chartId, uuid: args.uuid, property: args.property?.path, payload: error.message }
          ))),
          finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
            { categoryId: args.loadingCategoryId ?? 'timeseries', propertyPath: args.property?.path }))
          )
        )
    })
  ));

  loadTimeseries$ = createEffect(() => this.actions$.pipe(
    ofType(TimeseriesActionTypeNames.LOAD_TIMESERIES),
    mergeMap((args: TimeseriesRequest) => {
      this.store.dispatch(loadingActionTypes.load({
        categoryId: args.loadingCategoryId ?? 'timeseries',
        subcategoryId: args.loadingSubcategoryId ?? args.chartId,
        propertyPath: args.property?.path,
        propertyName: args.property?.name
      }));

      if (args.annotations) {
        return this.service.getAllTimeseriesValuesForProperty(args.uuid, args.property?.path, args.from, args.to)
          .pipe(
            map((data) => loadTimeseriesSuccess({
              chartId: args.chartId, uuid: args.uuid, property: args.property,
              from: args.from, to: args.to,
              payload: data, color: args.color, extended: args.extended, annotations: args.annotations
            })),
            catchError((error: any) =>
              of(loadTimeseriesFail(
                { chartId: args.chartId, uuid: args.uuid, property: args.property?.path, payload: error }
              ))
            ),
            finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
              { categoryId: args.loadingCategoryId ?? 'timeseries', propertyPath: args.property?.path }))
            )
          );
      } else if (args.extended) {
        return this.service.getExtendedValuesForProperty(args.uuid, args.property?.path, args.from, args.to)
          .pipe(
            map((data) => loadTimeseriesSuccess({
              chartId: args.chartId, uuid: args.uuid, property: args.property,
              from: args.from, to: args.to,
              payload: data, color: args.color, extended: args.extended
            })),
            catchError((error: any) => of(loadTimeseriesFail(
              { chartId: args.chartId, uuid: args.uuid, property: args.property?.path, payload: error }
            ))),
            finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
              { categoryId: args.loadingCategoryId ?? 'timeseries', propertyPath: args.property?.path }))
            )
          );
      } else {
        return this.service.getTimeseriesChanges(args.uuid, args.property?.path, args.from, args.to, args.limit, args.cursor)
          .pipe(
            map((data) => loadTimeseriesSuccess({
              chartId: args.chartId, uuid: args.uuid, property: args.property,
              from: args.from, to: args.to,
              payload: data, color: args.color, extended: args.extended, annotations: args.annotations
            })),
            catchError((error: any) => of(loadTimeseriesFail(
              { chartId: args.chartId, uuid: args.uuid, property: args.property?.path, payload: error }
            ))),
            finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
              { categoryId: args.loadingCategoryId ?? 'timeseries', propertyPath: args.property?.path }))
            )
          );
      }
    })
  ));

  loadNextTimeseriesSpectrum$ = createEffect(() => this.actions$.pipe(
    ofType(TimeseriesActionTypeNames.LOAD_NEXT_TIMESERIES_SPECTRUM),
    mergeMap((args: SpectraRequest) => {
      this.store.dispatch(loadingActionTypes.load({
        categoryId: args.loadingCategoryId ?? (args.isAirgap ? 'airgap' : 'spectra'),
        subcategoryId: args.loadingSubcategoryId ?? args.chartId,
        propertyPath: args.property?.path,
        propertyName: args.property?.name
      }));

      return this.service.loadNextTimeseriesSpectrum(
        args.uuid, args.property?.path, args.timestamp, args.isAirgap)
        .pipe(
          map((data: any) => loadTimeseriesSpectrumSuccess({
            chartId: args.chartId,
            uuid: args.uuid,
            property: args.property,
            timestamp: data.timestamp,
            currentPage: args.currentPage + 1,
            pageCount: args.pageCount,
            payload: data,
            xMin: data.xDomain[0],
            xMax: data.xDomain[1],
            from: data.from,
            to: data.to,
            color: args.color
          })),
          catchError((error: any) => of(loadTimeseriesSpectrumFail(
            { uuid: args.uuid, property: args.property?.path, payload: error }
          ))),
          finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
            { categoryId: args.loadingCategoryId ?? (args.isAirgap ? 'airgap' : 'spectra'), propertyPath: args.property?.path }))
          )
        )
    })
  ));

  loadPreviousTimeseriesSpectrum$ = createEffect(() => this.actions$.pipe(
    ofType(TimeseriesActionTypeNames.LOAD_PREVIOUS_TIMESERIES_SPECTRUM),
    mergeMap((args: SpectraRequest) => {
      this.store.dispatch(loadingActionTypes.load({
        categoryId: args.loadingCategoryId ?? (args.isAirgap ? 'airgap' : 'spectra'),
        subcategoryId: args.loadingSubcategoryId ?? args.chartId,
        propertyPath: args.property?.path,
        propertyName: args.property?.name
      }));

      return this.service.loadPreviousTimeseriesSpectrum(
        args.uuid, args.property?.path, args.timestamp, args.isAirgap)
        .pipe(
          map((data: any) => loadTimeseriesSpectrumSuccess({
            chartId: args.chartId,
            uuid: args.uuid,
            property: args.property,
            timestamp: data.timestamp,
            currentPage: args.currentPage - 1,
            pageCount: args.pageCount,
            payload: data,
            xMin: data.xDomain[0],
            xMax: data.xDomain[1],
            from: data.from,
            to: data.to,
            color: args.color
          })),
          catchError((error: any) => of(loadTimeseriesSpectrumFail(
            { uuid: args.uuid, property: args.property?.path, payload: error }
          ))),
          finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
            { categoryId: args.loadingCategoryId ?? (args.isAirgap ? 'airgap' : 'spectra'), propertyPath: args.property?.path }))
          )
        )
    })
  ));

  loadInitialTimeseriesSpectrum$ = createEffect(() => this.actions$.pipe(
    ofType(TimeseriesActionTypeNames.LOAD_INITIAL_TIMESERIES_SPECTRUM),
    mergeMap((args: SpectraRequest) => {
      this.store.dispatch(loadingActionTypes.load({
        categoryId: args.loadingCategoryId ?? (args.isAirgap ? 'airgap' : 'spectra'),
        subcategoryId: args.loadingSubcategoryId ?? args.chartId,
        propertyPath: args.property?.path,
        propertyName: args.property?.name
      }));

      return combineLatest([
        this.service.loadPreviousTimeseriesSpectrum(args.uuid, args.property?.path, args.timestamp, args.isAirgap),
        this.service.getTimeseriesCount(args.uuid, args.property?.path, args.from, args.to)])
        .pipe(
          take(1),
          map(([data, count]) => loadTimeseriesSpectrumSuccess({
            chartId: args.chartId,
            uuid: args.uuid,
            property: args.property,
            timestamp: data.timestamp,
            currentPage: count,
            pageCount: count,
            payload: count > 0 ? data : { coord: [] },
            xMin: data.xMin,
            xMax: data.xMax,
            from: data.from,
            to: data.to,
            color: args.color
          })),
          catchError((error: any) => of(loadTimeseriesSpectrumFail(
            { uuid: args.uuid, property: args.property?.path, payload: error }
          ))),
          finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
            { categoryId: args.loadingCategoryId ?? (args.isAirgap ? 'airgap' : 'spectra'), propertyPath: args.property?.path }))
          )
        )
    })
  ));

  loadTimeseriesWithResolution$ = createEffect(() => this.actions$.pipe(
    ofType(TimeseriesActionTypeNames.LOAD_TIMESERIES_RESOLUTION),
    mergeMap((args: TimeseriesResolutionRequest) => {
      this.store.dispatch(loadingActionTypes.load({
        categoryId: args.loadingCategoryId ?? 'timeseries',
        subcategoryId: args.loadingSubcategoryId ?? args.chartId,
        propertyPath: args.property?.path,
        propertyName: args.property?.name
      }));

      if (args.operatingPoint && args.operatingPoint !== 'all') {
        return this.fingerprintService.getValuesByOperatingPoint(args.uuid, args.property?.path, args?.operatingPoint, args.from, args.to)
          .pipe(
            map((data) => loadTimeseriesResolutionSuccess({
              chartId: args.chartId, uuid: args.uuid, property: args.property,
              from: args.from, to: args.to, payload: data?.data?.map(element => element.attributes), color: args.color,
              extended: args.extended,
            })),
            catchError((error: any) => of(loadTimeseriesFail(
              { chartId: args.chartId, uuid: args.uuid, property: args.property?.path, payload: error }
            ))),
            finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
              { categoryId: args.loadingCategoryId ?? 'timeseries', propertyPath: args.property?.path }))
            )
          );
      } else if (args.extended) {
        return this.service.getExtendedResolutionValuesForProperty(
          args.uuid, args.property?.path,
          args.from, args.to, args.resolution || 1000).pipe(
          map((data) => loadTimeseriesResolutionSuccess({
            chartId: args.chartId, uuid: args.uuid, property: args.property,
            from: args.from, to: args.to,
            payload: data, color: args.color, extended: args.extended
          })),
          catchError((error: any) => of(loadTimeseriesFail(
            { chartId: args.chartId, uuid: args.uuid, property: args.property?.path, payload: error.message }
          ))),
          finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
            { categoryId: args.loadingCategoryId ?? 'timeseries', propertyPath: args.property?.path }))
          )
        );
      } else {
        const defaultTo = moment();
        const defaultFrom = defaultTo.clone().subtract(4, 'week');
        return this.service.getResolutionValuesForProperty(
          args.uuid, args.property?.path,
          args.from || defaultFrom.toISOString(), args.to || defaultTo.toISOString(), args.resolution,
          args.includeCount, args.includeAverage).pipe(
          map((data) => loadTimeseriesResolutionSuccess({
            chartId: args.chartId, uuid: args.uuid, property: args.property,
            from: args.from || defaultFrom.toISOString(), to: args.to || defaultTo.toISOString(),
            payload: data, color: args.color, extended: args.extended
          })),
          catchError((error: any) => of(loadTimeseriesFail(
            { chartId: args.chartId, uuid: args.uuid, property: args.property?.path, payload: error.message }
          ))),
          finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
            { categoryId: args.loadingCategoryId ?? 'timeseries', propertyPath: args.property?.path }))
          )
        );
      }
    })
  ));

  loadTimeseriesWithResolutionChunks$ = createEffect(() => this.actions$.pipe(
    ofType(TimeseriesActionTypeNames.LOAD_TIMESERIES_CHANGES),
    mergeMap((args: TimeseriesResolutionRequest) => {
      this.store.dispatch(loadingActionTypes.load({
        categoryId: args.loadingCategoryId ?? 'timeseries',
        subcategoryId: args.loadingSubcategoryId ?? args.chartId,
        propertyPath: args.property?.path,
        propertyName: args.property?.name
      }));

      if (args.extended) {
        return this.service.getExtendedChangesValuesForProperty(args.uuid, args.property?.path, args.from, args.to)
          .pipe(
            map((data) => loadTimeseriesChangesSuccess({
              chartId: args.chartId, uuid: args.uuid, property: args.property,
              from: args.from, to: args.to,
              payload: data, color: args.color
            })),
            catchError((error: any) => of(loadTimeseriesFail(
              { chartId: args.chartId, uuid: args.uuid, property: args.property?.path, payload: error.message }
            ))),
            finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
              { categoryId: args.loadingCategoryId ?? 'timeseries', propertyPath: args.property?.path }))
            )
          );
      } else {
        return this.service.getAllTimeseriesValuesChangesForProperty(args.uuid, args.property?.path, args.from, args.to)
          .pipe(
            map((data) => loadTimeseriesChangesSuccess({
              chartId: args.chartId, uuid: args.uuid, property: args.property,
              from: args.from, to: args.to,
              payload: data, color: args.color
            })),
            catchError((error: any) => of(loadTimeseriesFail(
              { chartId: args.chartId, uuid: args.uuid, property: args.property?.path, payload: error.message }
            ))),
            finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
              { categoryId: args.loadingCategoryId ?? 'timeseries', propertyPath: args.property?.path }))
            )
          );
      }
    })
  ));

  constructor(
    private actions$: Actions,
    private service: TimeseriesService,
    private store: Store,
    private fingerprintService: FingerprintService
  ) { }
}
