import { ActionReducerMap } from '@ngrx/store';
import { INNodeModel } from '../@core/model/asset.model';
import {
  ChartConfigurationState,
  initialState as chartConfigurationInitialState,
  reducer as chartConfigurationReducers,
} from '../charts/+store/reducers/chart-configuration.reducer';
import { ChartDataState, initialState as chartDataInitialState, reducer as chartDataReducers } from '../charts/+store/reducers/chart-data.reducer';
import { ChartOptionsState, initialState as chartOptionsInitialState, reducer as chartOptionsReducers } from '../charts/+store/reducers/chart-options.reducer';
import { AssetsState, initialState as assetsInitialState, assetsReducer, selectedAssetReducer } from './reducers/assets.reducer';
import { CustomPropertiesState, initialState as customPropertiesInitialState, reducer as customPropertiesReducer } from './reducers/custom-properties.reducer';
import { LoadingState, initialState as loadingInitialState, reducer as loadingReducer } from './reducers/loading.reducer';
import { PropertiesState, SelectedAssetPropertiesState, SelectedMasterAssetPropertiesState, initialState as propertiesInitialState, reducer as propertiesReducer, selectedAssetPropertiesInitialState, selectedAssetPropertiesReducer, selectedMasterAssetPropertiesInitialState, selectedMasterAssetPropertiesReducer } from './reducers/properties.reducer';
import { SpectrumChartMultiState, initialState as spectrumChartMultiInitialState, reducer as spectrumChartMultiReducer } from './reducers/spectrum-chart-multi.reducer';
import { SpectrumState, initialState as spectrumInitialState, reducer as spectrumReducer } from './reducers/spectrum.reducer';
import { SubsetsState, initialState as subsetsInitialState, reducer as subsetsReducer } from './reducers/subsets.reducer';
import { TimeState, reducer, timeInitialState } from './reducers/time.reducer';
import { TimeseriesChartMultiState, initialState as timeseriesChartMultiInitialState, reducer as timeseriesChartMultiReducer } from './reducers/timeseries-chart-multi.reducer';
import { TimeseriesState, initialState as timeseriesInitialState, reducer as timeseriesReducer } from './reducers/timeseries.reducer';
import { UserAssetsState, initialState as userAssetsInitialState, reducer as userAssetsReducer } from './reducers/user-assets.reducer';
import { UserPropertiesState, initialState as userPropertiesInitialState, reducer as userPropertiesReducer } from './reducers/user-properties.reducer';
export class AppState {
  chartConfiguration: ChartConfigurationState;
  chartOptions: ChartOptionsState;
  chartData: ChartDataState;
  assets: AssetsState;
  selectedAsset: INNodeModel;
  userAssets: UserAssetsState;
  userProperties: UserPropertiesState;
  timeseries: TimeseriesState;
  spectrum: SpectrumState;
  timeseriesChartMulti: TimeseriesChartMultiState;
  spectrumChartMulti: SpectrumChartMultiState;
  properties: PropertiesState;
  selectedAssetProperties: SelectedAssetPropertiesState;
  selectedMasterAssetProperties: SelectedMasterAssetPropertiesState;
  customProperties: CustomPropertiesState;
  subsets: SubsetsState;
  time: TimeState;
  loading: LoadingState;
}

export const initialState: AppState = {
  chartConfiguration: chartConfigurationInitialState,
  chartOptions: chartOptionsInitialState,
  chartData: chartDataInitialState,
  assets: assetsInitialState,
  selectedAsset: undefined,
  userAssets: userAssetsInitialState,
  userProperties: userPropertiesInitialState,
  timeseries: timeseriesInitialState,
  timeseriesChartMulti: timeseriesChartMultiInitialState,
  spectrum: spectrumInitialState,
  spectrumChartMulti: spectrumChartMultiInitialState,
  properties: propertiesInitialState,
  selectedAssetProperties: selectedAssetPropertiesInitialState,
  selectedMasterAssetProperties: selectedMasterAssetPropertiesInitialState,
  customProperties: customPropertiesInitialState,
  subsets: subsetsInitialState,
  time: timeInitialState,
  loading: loadingInitialState
};

export const reducers: ActionReducerMap<AppState> = {
  chartConfiguration: chartConfigurationReducers,
  chartOptions: chartOptionsReducers,
  chartData: chartDataReducers,
  assets: assetsReducer,
  selectedAsset: selectedAssetReducer,
  userAssets: userAssetsReducer,
  userProperties: userPropertiesReducer,
  timeseries: timeseriesReducer,
  timeseriesChartMulti: timeseriesChartMultiReducer,
  spectrum: spectrumReducer,
  spectrumChartMulti: spectrumChartMultiReducer,
  properties: propertiesReducer,
  selectedAssetProperties: selectedAssetPropertiesReducer,
  selectedMasterAssetProperties: selectedMasterAssetPropertiesReducer,
  customProperties: customPropertiesReducer,
  subsets: subsetsReducer,
  time: reducer,
  loading: loadingReducer
};
