import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, last, map, mergeMap } from 'rxjs/operators';
import { AssetsService } from 'src/app/@core/services/assets.service';
import { AssetsActionTypeNames, assetsActionTypes } from '../actions/asset.actions';

@Injectable()
export class AssetsEffects {
  loadAvailableAssets$ = createEffect(() => this.actions$.pipe(
    ofType(AssetsActionTypeNames.LOAD_AVAILABLE_ASSETS),
    mergeMap(
      () => this.service.getAssets()
        .pipe(
          last(),
          map((data: any) =>
            assetsActionTypes.loadAvailableAssetsSuccess(
              { payload: data }
            )
          ),
          catchError((error: any) => {
            return of(assetsActionTypes.loadAvailableAssetsFail(
              { payload: error }
            ))
          }
          )
        ))
  ));

  updateAsset$ = createEffect(() => this.actions$.pipe(
    ofType(AssetsActionTypeNames.UPDATE_ASSET),
    mergeMap(
      ((args: { assetId: string }) => this.service.getAsset(args.assetId)
        .pipe(
          last(),
          map((data: any) => {
            if (data) {
              this.service.setAsset(data)
            }
            return assetsActionTypes.addAsset(
              { asset: data }
            )
          }
          ),
          catchError((error: any) => {
            return of(assetsActionTypes.addAsset(
              { asset: undefined }
            ))
          }
          )
        ))
    )
  ));

  constructor(
    private actions$: Actions,
    private service: AssetsService,
  ) { }
}
