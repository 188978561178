import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PRIMARY_OUTLET, Params, Router, RouterModule } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { NbIconLibraries, NbIconModule, NbLayoutModule, NbSidebarModule, NbSpinnerModule } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { MatomoModule, MatomoTracker } from 'ngx-matomo-client';
import { Subscription, combineLatest, take } from 'rxjs';
import { CookieComponent } from './@core/components/disclaimer/disclaimer-cookies/cookie.component';
import { HeaderComponent } from './@core/components/header/header.component';
import { SideMenuContainerComponent } from './@core/components/side-menu-container/side-menu-container.component';
import { AppStateService } from './@core/services/app-state.service';
import { AssetStoreService } from './@core/services/asset-store.service';
import { Auth0Service } from './@core/services/auth/auth.service';
import { ClientService } from './@core/services/client.service';
import { RoutingParameterService } from './@core/services/routing-parameter.service';
import { UserService } from './@core/services/user.service';
import { LocaleService } from './@shared/services/locale.service';
import { SessionStorageService } from './@shared/services/session-storage.service';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    NbLayoutModule,
    NgIf,
    NbIconModule,
    NbSpinnerModule,
    NbSidebarModule,
    AsyncPipe,
    HeaderComponent,
    MatomoModule,
    CookieComponent,
    RouterModule,
    SideMenuContainerComponent,
  ],
})
export class AppComponent implements OnInit {
  assetStateSubscription: Subscription;
  subscriptions: Array<Subscription> = [];
  hasToken = false;
  showDisclaimerFlag = false;
  constructor(
    private translate: TranslateService,
    private localeService: LocaleService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private routingParameterService: RoutingParameterService,
    public appState: AppStateService,
    private userService: UserService,
    public auth0: Auth0Service,
    public auth: AuthService,
    private matomoTracker: MatomoTracker,
    private clientService: ClientService,
    private assetStoreService: AssetStoreService,
    private iconsLibrary: NbIconLibraries,
  ) {
    translate.setDefaultLang('en');
    localeService.switchLanguage(localeService.language());
    matomoTracker.forgetConsentGiven();
    if (window.location.protocol === 'https') {
      matomoTracker.setSecureCookie(true);
    }
    iconsLibrary.registerSvgPack('sidriveiq', {
      iqsuitePartialDischarge: '<img src="assets/icons/Menu/Partial-Discharge_24px.png">',
      csAlarm: '<img src="assets/icons/Condition-State-small/Condition-State-Alarm.svg">',
      csCritical: '<img src="assets/icons/Condition-State-small/Condition-State-Critical.svg">',
      csDN: '<img src="assets/icons/Condition-State-small/Condition-State-DN.svg">',
      csError: '<img src="assets/icons/Condition-State-small/Condition-State-Error.svg">',
      csNA: '<img src="assets/icons/Condition-State-small/Condition-State-NA.svg">',
      csNV: '<img src="assets/icons/Condition-State-small/Condition-State-NV.svg">',
      csOk: '<img src="assets/icons/Condition-State-small/Condition-State-Ok.svg">',
      csRunning: '<img src="assets/icons/Condition-State-small/Condition-State-Running.svg">',
      csTimeout: '<img src="assets/icons/Condition-State-small/Condition-State-Timeout.svg">',
      csWarning: '<img src="assets/icons/Condition-State-small/Condition-State-Warning.svg">',
      csWatch: '<img src="assets/icons/Condition-State-small/Condition-State-Watch.svg">',
      hierarchy: '<img src="assets/icons/hierarchy.svg">',
      toolsCursor: '<img src="assets/icons/tools/tools-outline_Cursor.svg">',
      toolsCursorLock: '<img src="assets/icons/tools/tools-outline_Cursor-Lock.svg">',
      toolsCursorTooltip: '<img src="assets/icons/tools/tools-outline_Cursor-Tooltip.svg">',
      tools: '<img src="assets/icons/tools/tools-outline_Tools.svg">',
      toolsTooltips: '<img src="assets/icons/tools/tools-outline_Tooltip.svg">',
      newBadge: '<img src="assets/icons/new-badge.svg">',
      filePreview: '<img src="assets/icons/file-preview.svg">',
      fileDownloaded: '<img src="assets/icons/file-downloaded.svg">',
      collapseAll: '<img src="assets/icons/collapse-all.svg">',
      cellsBypassed: '<img src="assets/icons/cell-bypass.svg">',
      sortAsc: '<img src="assets/icons/List/sort_asc_24px.png">',
      sortDesc: '<img src="assets/icons/List/sort_desc_24px.png">',
    });
  }

  ngOnInit(): void {
    // initialize tenants
    const tenantList = this.clientService.getTenantsFromSessionStorage();
    if (!tenantList) {
      this.clientService.setTenantsInSessionStorage();
    }

    // disclaimer check
    this.clientService.loadUser();

    this.subscriptions.push(
      this.auth.user$.subscribe((user) => {
        if (user) {
          const mailIdx = user.email.indexOf('@');
          if (mailIdx > -1) {
            this.matomoTracker.setCustomVariable(
              1,
              'VisitorType',
              user.email.substring(mailIdx + 1).includes('siemens') || user.email.substring(mailIdx + 1).includes('innomotics')
                ? 'internal'
                : 'ext',
              'visit',
            );
          }

          this.userService
            .fetchSettingsFromFacade()
            .pipe(take(1))
            .subscribe((settings) => {
              combineLatest(settings)
                .pipe(take(1))
                .subscribe((userValues: any) => {
                  if (
                    Object.keys(userValues).some((server) => {
                      let result = false;
                      userValues[server]?.forEach((userValue) => {
                        result = result === true ? true : this.showDisclaimer(this.userService.cleanupSettingsString(userValue.value));
                      });
                      return result;
                    })
                  ) {
                    this.showDisclaimerFlag = true;
                    this.auth0.userSettings$.next(userValues);
                    this.router.navigateByUrl('disclaimer');
                  }
                  this.appState.showTerms$.next(this.showDisclaimerFlag);
                });
            });
        }
      }),
    );

    const cookieSettings = localStorage.getItem('cookieSettings');
    if (cookieSettings) {
      const cookieSettingsJson = JSON.parse(cookieSettings);
      this.appState.showCookieConsent$.next(false);
      this.appState.cookieSettings$.next(cookieSettingsJson);
      if (cookieSettingsJson.performanceAccepted === true) {
        this.matomoTracker.rememberConsentGiven();
        this.matomoTracker.setUserId(window.env.matomoStage);
      }
      this.appState.cookieSettings$.next(JSON.parse(cookieSettings));
    } else {
      this.matomoTracker.forgetConsentGiven();
    }

    this.routingParameterService.params.subscribe((params: Params): void => {
      if (!params?.deviceId && !params?.nodeId) this.assetStoreService.resetSelectedAsset();
    });

    this.assetStoreService.loadAvailableAssets();

    window.onunload = (): void => {
      this.sessionStorageService.clearAssets();
    };
  }

  public showDisclaimer(settings: any): boolean {
    const legal = settings.orgSettings.legalInfo.documents;
    return legal.some((doc) => {
      return (
        (doc.confirm === true && doc.accepted === undefined) || (doc.confirm === true && new Date(doc.acceptedAt) < new Date(doc.released))
      );
    });
  }

  // private checkSurvey(): void {
  //   const surveyStatus = localStorage.getItem('nosurvey');
  //   this.appState.showSurvey$.next( surveyStatus === undefined || surveyStatus !== 'true');
  // }

  menuClick(event: string): void {
    const urlTree = this.router.parseUrl(this.router.url);
    const path = urlTree.root.children[PRIMARY_OUTLET].segments.map((seg) => seg.path);

    if (path.length >= 7) {
      // we navigate
      // EITHER FROM: dashboard/diagnose/type/1600/device/lddevad0.16000/overview/airgap/airgap
      // OR FROM: dashboard/diagnose/type/1600/device/lddevad0.16000/overview/overview
      // TO: dashboard/diagnose/type/1600/device/lddevad0.16000/info
      const numberOfExtraPathElements = path.length - 6;
      for (let i = 0; i < numberOfExtraPathElements; i++) {
        path.pop();
      }
    }
    this.router.navigate([path.join('/') + '/' + event.toLocaleLowerCase()]);
  }
}