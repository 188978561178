import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, of, withLatestFrom } from 'rxjs';
import { TimePeriod } from 'src/app/@core/model/time-period';
import { TimeService } from 'src/app/@shared/services/time.service';
import { TimeActionTypeNames, TimeActionTypes } from '../actions/time.actions';
import { selectTimeRange } from '../selectors/time.selector';

@Injectable()
export class TimeEffects {
  loadTimeseries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimeActionTypeNames.UPDATE_TIMEZONE),
      withLatestFrom(this.store.select(selectTimeRange)),
      mergeMap(([args, range]: [any, TimePeriod]) =>
        of(TimeActionTypes.updateTimezoneSuccess({
          range: this.timeService.convertTimePeriodToCurrentTimeZone(
            range, args.zone, args.zoneInfo),
          zone: args.zone,
          zoneInfo: args.zoneInfo,
        }))
      ),
    )
  );

  convertIndividualTimerange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimeActionTypeNames.SET_INDIVIDUAL),
      mergeMap((args: any) => of(TimeActionTypes.setTimerangeSuccess(
        {
          range: this.timeService.convertTimePeriodToCurrentTimeZone(args.range),
          setting: 'individual',
          trigger: args.trigger ?? ''
        })))));

  convertPredefinedTimerange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimeActionTypeNames.SET_PREDEFINED),
      mergeMap((args: any) => {
        let range: TimePeriod = new TimePeriod(this.timeService.now(), this.timeService.now());

        switch (args.setting) {
          case 'today':
            range.startTime = this.timeService.now().startOf('day');
            break;
          case '24h':
            range.startTime = this.timeService.now().subtract(1, 'day');
            break;
          case 'week':
            range.startTime = this.timeService.now().subtract(1, 'week');
            break;
          case 'month':
            range.startTime = this.timeService.now().subtract(1, 'month');
            break;
          case '3months':
            range.startTime = this.timeService.now().subtract(3, 'month');
            break;
          case '6months':
            range.startTime = this.timeService.now().subtract(6, 'month');
            break;
          case 'year':
            range.startTime = this.timeService.now().subtract(1, 'year');
            break;
          default:
            range.startTime = this.timeService.now().subtract(1, 'month');
            break;
        }

        range = this.timeService.convertTimePeriodToCurrentTimeZone(range);

        return of(TimeActionTypes.setTimerangeSuccess(
          {
            range: range,
            setting: args.setting,
            trigger: args.trigger ?? ''
          }))
      })));

  constructor(
    private actions$: Actions,
    private store: Store,
    private timeService: TimeService) { }
}
