<nb-layout [windowMode]="false"
  *ngIf="(auth.isAuthenticated$ | async) && (appState.showTerms$ | async) === false; else unauthorized">
  <nb-layout-header fixed>
    <app-header></app-header>
  </nb-layout-header>

  <nb-layout-column>
    <!-- <app-survey *ngIf="appState.showSurvey$ | async"></app-survey> -->
    <app-cookie *ngIf="appState.showCookieConsent$ | async"></app-cookie>
    <router-outlet></router-outlet>
    <nb-spinner *ngIf="appState.isLoading$ | async"></nb-spinner>
  </nb-layout-column>

  <nb-sidebar responsive class="menu-sidebar start" tag="menu-sidebar">
    <app-side-menu-container></app-side-menu-container>
  </nb-sidebar>
</nb-layout>

<ng-template #unauthorized>
  <nb-layout>
    <nb-layout-column>
      <router-outlet></router-outlet>
    </nb-layout-column>
  </nb-layout>
</ng-template>
