import { createAction, props } from '@ngrx/store';

export enum ChartDataActionTypeNames {
  CREATE_DATA = '[ChartData] Create data entry for chart',
  UPDATE_DATA = '[ChartData] Update chart data',
  DELETE_DATA = '[ChartData] Delete chart data',
  RESET = '[ChartOptions] Restore initial state',
}

export const createData = createAction(
  `${ChartDataActionTypeNames.CREATE_DATA}`,
  props<{
    chartId: string;
    propertyPath: string
    data: any;
  }>()
);


export const updateData = createAction(
  `${ChartDataActionTypeNames.UPDATE_DATA}`,
  props<{
    chartId: string;
    propertyPath: string;
    data: any;
  }>()
);


export const deleteData = createAction(
  `${ChartDataActionTypeNames.DELETE_DATA}`,
  props<{
    chartId: string;
    propertyPath: string;
  }>()
);

export const reset = createAction(`${ChartDataActionTypeNames.RESET}`);

export const chartDataActionTypes = {
  createData,
  updateData,
  deleteData,
  reset,
};
