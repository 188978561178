import { ExtraOptions, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { DisclaimerTermsComponent } from './@core/components/disclaimer/disclaimer-terms/disclaimer-terms.component';
import { LogoutComponent } from './@core/components/logout/logout.component';
import { ProfileComponent } from './@core/components/profile/profile.component';
import { AssetCacheGuard } from './@core/guards/asset-cache.guard';
import { ChmGuard } from './@core/guards/chm.guard';
import { RoleGuard } from './@core/guards/role.guard';
import { AuthorizationProblemComponent } from './@core/pages/authorization-problem/authorization-problem.component';
import { HomeComponent } from './@core/pages/home/home.component';
import { Page404Component } from './@core/pages/page404/page404.component';


export const appRoutes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '',
    data: {
      role: 'all',
    },
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'users',
    loadChildren: () => import('./user-management/user-management.module').then((m) => m.UserManagementModule),
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'users-management',
    loadChildren: () => import('./user-management-v2/user-management-v2.module').then((m) => m.UserManagementV2Module),
    canActivate: [AuthGuard, RoleGuard, AssetCacheGuard],
  },
  {
    path: 'assetmanagement',
    loadChildren: () => import('./asset-management/asset-management.module').then((m) => m.AssetManagementModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      role: 'iqsuite.editor',
    },
  },
  {
    path: 'maintenance-management',
    loadChildren: () => import('./maintenance-slot/maintenance-slot.module').then((m) => m.MaintenanceSlotModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      role: 'iqsuite.contributor',
    }
  },
  {
    path: 'hierarchy-management',
    loadChildren: () => import('./hierarchy-management/hierarchy-management.module').then((m) => m.HierarchyManagementModule),
    canActivate: [AuthGuard, RoleGuard, ChmGuard],
  },
  {
    // ##Important By changing the path of hierarchies, URL in ChmGuard must be changed and
    // the variable "baseAssetHierarchyPath" in helper file must be changed according to the new path
    path: 'central-hierarchy-management',
    loadChildren: () =>
      import('./asset-hierarchy-management/asset-hierarchy-management.module').then((m) => m.AssetHierarchyManagementModule),
    canActivate: [AuthGuard, RoleGuard, ChmGuard],
  },
  {
    path: 'agent-management',
    loadChildren: () => import('./agent-management/agent-management.module').then((m) => m.AgentManagementModule),
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'organisation-management',
    loadChildren: () => import('./organisation-management/organisation-management.module').then((m) => m.OrganisationManagementModule),
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      role: 'user',
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'home',
    canActivate: [AuthGuard, RoleGuard],
    runGuardsAndResolvers: 'always',
    data: {
      role: 'all',
    },
    component: HomeComponent,
  },
  {
    path: 'dashboard',
    pathMatch: 'prefix',
    data: {
      role: 'all',
    },
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'diagnose',
        pathMatch: 'prefix',
        data: {
          role: 'all',
          viewMode: 'diagnose',
        },
        loadChildren: () => import('./asset-list/asset-list.module').then((m) => m.AssetListModule),
      },
      {
        path: 'fleet',
        loadChildren: () => import('./asset-list/asset-list.module').then((m) => m.AssetListModule),
        data: {
          role: 'all',
          viewMode: 'fleet',
        },
      },
      {
        path: '**',
        data: {
          role: 'all',
        },
        redirectTo: 'fleet',
      },
    ],
  },
  {
    path: 'filedownload',
    canActivate: [AuthGuard, RoleGuard, AssetCacheGuard],
    loadComponent: () => import('./@shared/components/filedownload/filedownload.component')
      .then(mod => mod.FiledownloadComponent),
    data: {
      role: 'iqsuite.contributor',
    },
  },
  {
    path: 'multichart',
    canActivate: [AuthGuard, RoleGuard, AssetCacheGuard],
    loadChildren: () => import('./multi-chart/multi-chart.module')
      .then(m => m.MultiChartModule),
    data: {
      role: 'iqsuite.contributor'
    },
  },
  {
    path: 'nominal',
    canActivate: [AuthGuard, RoleGuard, AssetCacheGuard],
    loadChildren: () => import('./timeseries/timeseries.module')
      .then(m => m.TimeseriesModule),
    data: {
      role: 'iqsuite.contributor'
    },
  },
  {
    path: 'onboarding',
    pathMatch: 'prefix',
    loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
    canActivate: [AuthGuard, RoleGuard],
    // data: {
    //   role: 'iqsuite.superuser',
    // },
  },
  {
    path: 'reports-handling',
    loadChildren: () => import('./reports-handling/reports-handling.module')
      .then(m => m.ReportsHandlingModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      role: 'iqsuite.contributor'
    },
  },
  {
    path: 'motor-health',
    canActivate: [AuthGuard, RoleGuard, AssetCacheGuard],
    loadChildren: () => import('./motor-health/motor-health.module')
      .then(m => m.MotorHealthModule),
    data: {
      role: 'iqsuite.contributor',
      view: 'analytics'
    }
  },
  {
    path: 'iq-shift',
    canActivate: [AuthGuard, RoleGuard, AssetCacheGuard],
    loadChildren: () => import('./iq-shift/iq-shift.module')
      .then(m => m.IqShiftModule),
    data: {
      role: 'iqsuite.contributor'
    }
  },
  {
    path: 'edge-configuration',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['iqsuite.edge']
    },
    children: [
      {
        path: 'device-configuration',
        pathMatch: 'prefix',
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () => import('@inspire-iq/iq-create')
          .then(m => m.IqCreateModule),
        data: {
          permissions: ['iqsuite.edge']
        }
      },
    ]
  },
  {
    path: 'authentication-problem',
    component: AuthorizationProblemComponent,
  },
  {
    path: 'disclaimer',
    canActivate: [AuthGuard, RoleGuard],
    component: DisclaimerTermsComponent
  },
  {
    path: '**',
    component: Page404Component,
  },
];

export const routerConfig: ExtraOptions = {
  useHash: true
  // enableTracing: true,
};
