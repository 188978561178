import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class IqMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    if (params.interpolateParams) {
      return (params.interpolateParams as any).Default || params.key;
    }
    return params.key;
  }
}
