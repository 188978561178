import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer } from '@ngrx/store';
import { Properties } from 'src/app/timeseries/model/property.model';

export const customPropertiesFeatureKey = 'customProperties';

export interface CustomPropertiesState extends EntityState<Properties> {
  error: string;
}

export const adapter: EntityAdapter<Properties> = createEntityAdapter<Properties>(
  {
    selectId: (properties) => properties.chartId
  }
);

export const initialState = adapter.getInitialState({
  error: '',
});

export const reducer = createReducer(
  initialState,
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const getError = (state: CustomPropertiesState): string => state.error;
