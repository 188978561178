import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import {
  NbButtonModule,
  NbDialogService,
  NbIconModule,
  NbMenuBag,
  NbMenuItem,
  NbMenuModule,
  NbMenuService,
  NbSidebarService,
} from '@nebular/theme';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { LocaleService } from 'src/app/@shared/services/locale.service';
import { LanguageComponent } from '../../../@shared/components/language/language.component';
import { TechnicalSupportComponent } from '../../../@shared/components/support/technical-support.component';
import { AppStateService } from '../../services/app-state.service';
import { Auth0Service } from '../../services/auth/auth.service';
import { CopyrightComponent } from '../copyright/copyright.component';

const enum DIALOGTYPE {
  'language',
  'support',
}

@Component({
  standalone: true,
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  imports: [NbMenuModule, CopyrightComponent, NbIconModule, NbButtonModule],
  providers: [TranslateService, LocaleService, AppStateService],
})
export class SideMenuComponent implements OnDestroy {
  public icon = 'arrowhead-left-outline';
  public expanded = true;
  subscriptions: Array<Subscription> = [];

  public menuItems: NbMenuItem[] = [
    {
      title: 'Diagnose',
      icon: 'trending-up-outline',
      link: '/dashboard/diagnose',
      data: {
        menuItemName: 'diagnose',
        localizationKey: 'menus.entries.diagnose',
        role: 'all',
      },
      expanded: false,
      pathMatch: 'prefix',
      group: false,
    },
    {
      title: 'Fleet',
      icon: 'layers-outline',
      link: '/dashboard/fleet',
      data: {
        menuItemName: 'fleet',
        localizationKey: 'menus.entries.fleet',
        role: 'all',
      },
      expanded: false,
      pathMatch: 'prefix',
      group: false,
    },
    {
      title: 'Analytics',
      icon: 'options-2-outline',
      data: {
        menuItemName: 'fleet',
        localizationKey: 'menus.entries.analytics',
        role: 'iqsuite.contributor',
      },
      children: [
        {
          title: 'Kibana',
          hidden: true,
          link: '/pages/dashboard',
          data: {
            menuItemName: 'kibana',
            localizationKey: 'menus.entries.kibana',
          },
        },
        {
          title: 'File Download',
          link: '/filedownload',
          data: {
            menuItemName: 'fileDownload',
            localizationKey: 'menus.entries.fileDownload',
            role: 'iqsuite.contributor',
          },
        },
        {
          title: 'Reports Handling',
          link: '/reports-handling',
          data: {
            menuItemName: 'reports',
            localizationKey: 'menus.entries.reportsHandling',
            role: 'iqsuite.contributor',
          },
        },
        {
          title: 'Timeseries Analysis',
          link: '/multichart',
          data: {
            menuItemName: 'timeseriesAnalysis',
            localizationKey: 'menus.entries.timeseriesAnalysis',
            role: 'iqsuite.contributor',
          },
        },
        {
          title: 'Nominal Timeseries Analysis',
          link: '/nominal',
          data: {
            menuItemName: 'nominalTimeseriesAnalysis',
            localizationKey: 'menus.entries.nominalTimeseriesAnalysis',
            role: 'iqsuite.contributor',
          },
          badge: {
            text: 'beta',
            status: 'basic',
          },
        },
        {
          title: 'Motor Health',
          link: '/motor-health',
          data: {
            menuItemName: 'motorHealth',
            localizationKey: 'menus.entries.motorHealth',
            role: 'iqsuite.contributor',
          },
        },
      ],
    },
    {
      title: 'Asset Onboarding',
      icon: 'plus-circle-outline',
      link: '/onboarding',
      data: {
        menuItemName: 'onboarding',
        localizationKey: 'menus.entries.onboarding',
        // role: 'iqsuite.superuser',
      },
      expanded: false,
      pathMatch: 'full',
      group: false,
    },
    {
      title: 'Operation',
      icon: 'pie-chart-outline',
      data: {
        menuItemName: 'operation',
        localizationKey: 'menus.entries.operation',
        // role: 'iqsuite.superuser',
      },
      children: [
        {
          title: 'AWS CloudWatch',
          target: '_blank',
          url: 'https://aws.siemens.cloud',
          data: {
            menuItemName: 'cloudWatch',
            localizationKey: 'menus.entries.cloudWatch',
          },
          icon: 'external-link-outline',
        },
      ],
    },
    {
      title: 'Configuration',
      icon: 'options-2-outline',
      data: {
        menuItemName: 'configuration',
        localizationKey: 'menus.entries.configuration',
        role: 'iqsuite.contributor',
        permissions: ['iqsuite.edge'],
      },
      children: [
        {
          title: 'Assets',
          icon: 'cube-outline',
          link: '/assetmanagement',
          data: {
            menuItemName: 'assets',
            localizationKey: 'menus.entries.assets',
            role: 'iqsuite.editor',
          },
          expanded: false,
          pathMatch: 'full',
        },
        {
          title: 'Agents',
          icon: 'activity-outline',
          link: '/agent-management',
          data: {
            menuItemName: 'agentmanagement',
            localizationKey: 'menus.entries.agents',
            // role: 'iqsuite.superuser',
          },
          expanded: false,
          pathMatch: 'full',
        },
        {
          title: 'Hierarchies',
          icon: { icon: 'hierarchy', pack: 'sidriveiq' },
          link: window.env.region === 'cn' ? '/hierarchy-management' : '/central-hierarchy-management',
          data: {
            menuItemName: 'hierarchyManagement',
            localizationKey: 'menus.entries.hierarchyManagement',
            // role: 'iqsuite.superuser',
          },
          expanded: false,
          pathMatch: 'full',
        },
        {
          title: 'Maintenance',
          icon: 'cube-outline',
          link: '/maintenance-management',
          data: {
            menuItemName: 'maintenanceSlot',
            localizationKey: 'menus.entries.maintenanceSlot',
            role: 'iqsuite.contributor',
          },
          expanded: false,
          pathMatch: 'full',
        },
        {
          title: 'Organisations',
          icon: 'activity-outline',
          link: '/organisation-management',
          data: {
            menuItemName: 'organisationsmanagement',
            localizationKey: 'menus.entries.organisations',
            // role: 'iqsuite.superuser',
          },
          expanded: false,
          pathMatch: 'full',
        },
        {
          title: 'Users',
          icon: 'people-outline',
          link: '/users',
          data: {
            menuItemName: 'users',
            localizationKey: 'menus.entries.users',
            // role: 'iqsuite.superuser',
          },
          expanded: false,
        },
        {
          title: 'Users Management',
          icon: 'people-outline',
          link: '/users-management',
          data: {
            menuItemName: 'users-management',
            localizationKey: 'menus.entries.usersManagement',
            role: 'iqsuite.superuser',
          },
          badge: {
            text: 'beta',
            status: 'basic',
          },
          expanded: false,
        },
        {
          title: 'Edge Computing',
          icon: 'hard-drive-outline',
          data: {
            menuItemName: 'edgeComputing',
            localizationKey: 'menus.entries.edgeComputing',
            permissions: ['iqsuite.edge'],
          },
          badge: {
            text: 'beta',
            status: 'basic',
          },
          expanded: false,
          children: [
            {
              title: 'Device Configuration',
              icon: 'cloud-upload-outline',
              link: '/edge-configuration/device-configuration',
              data: {
                menuItemName: 'deviceConfiguration',
                localizationKey: 'menus.entries.deviceConfiguration',
                permissions: ['iqsuite.edge'],
              },
              expanded: false,
            },
          ],
        },
      ],
    },
    {
      title: 'Report',
      icon: 'book-open-outline',
      hidden: true,
      data: {
        menuItemName: 'report',
        localizationKey: 'menus.entries.report',
        // role: 'iqsuite.superuser',
      },
      children: [],
    },
    {
      title: 'Home',
      data: {
        menuItemName: '/home',
        localizationKey: 'home',
      },
      hidden: true,
    },
    {
      title: 'IQ Shift',
      icon: 'list-outline',
      link: '/iq-shift',
      data: {
        menuItemName: 'iqShift',
        localizationKey: 'menus.entries.iqShift',
        role: 'iqsuite.contributor',
      },

      expanded: false,
      pathMatch: 'prefix',
      group: false,
    },
    {
      title: 'Language',
      icon: 'globe-2-outline',
      data: {
        menuItemName: 'lang',
        localizationKey: 'menus.entries.language',
        role: 'all',
      },
    },
    {
      title: 'Technical Support',
      icon: 'email-outline',
      data: {
        menuItemName: 'support',
        localizationKey: 'menus.entries.technicalSupport',
        role: 'all',
      },
    },
  ];

  private destroy$ = new Subject<void>();
  selectedItem: string;

  constructor(
    private auth0: Auth0Service,
    private auth: AuthService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private dialogService: NbDialogService,
    private translateService: TranslateService,
    private localeService: LocaleService,
  ) {
    this.menuService.onItemClick().subscribe((itemClicked: NbMenuBag) => {
      if (itemClicked && itemClicked.item && itemClicked.item.data) {
        if (itemClicked.item.data.menuItemName === 'lang') {
          this.openDialog(DIALOGTYPE.language);
        }
        if (itemClicked.item.data.menuItemName === 'support') {
          this.openDialog(DIALOGTYPE.support);
        }
      }
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateEntries(this.menuItems);
    });

    this.subscriptions.push(
      this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.auth.getAccessTokenSilently().subscribe((token) => {
            if (token) {
              const temp: NbMenuItem[] = [];
              this.menuItems.forEach((item) => {
                if (this.auth0.userHasRole(token, item.data?.role, item.data?.permissions)) {
                  // TODO: make recursive if more than 2 levels
                  if (item.children) {
                    item.children = item.children.filter((ch) => {
                      return this.auth0.userHasRole(token, ch.data?.role, ch.data?.permissions);
                    });
                  }
                  temp.push(item);
                }
              });
              this.menuItems = temp;
            }
          });
        }
      }),
    );

    this.subscriptions.push(
      this.sidebarService.onToggle().subscribe(() => {
        this.expanded = !this.expanded;
        this.icon = this.expanded ? 'arrowhead-left-outline' : 'arrowhead-right-outline';
      }),
    );

    this.subscriptions.push(
      this.localeService.language$.subscribe((lang) => {
        if (lang) {
          this.translateEntries(this.menuItems);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    try {
      this.subscriptions.forEach((s) => s.unsubscribe());
    } catch {
      // nop
    }
  }

  toggleSidebar(): void {
    this.sidebarService.toggle(true, 'menu-sidebar');
  }

  protected openDialog(typeId: number): void {
    switch (typeId) {
      case DIALOGTYPE.language: {
        // nop for now
        this.dialogService.open(LanguageComponent, {
          autoFocus: false,
        });
        break;
      }
      case DIALOGTYPE.support: {
        this.dialogService.open(TechnicalSupportComponent, {
          autoFocus: false,
        });
        break;
      }
    }
  }

  private translateEntries(menuItems: NbMenuItem[]): void {
    menuItems.map((menuItem) => {
      menuItem.title = this.translateService.instant(menuItem.data.localizationKey);
      if (menuItem.children) {
        this.translateEntries(menuItem.children);
      }
    });
  }
}