import { NbJSThemeOptions } from '@nebular/theme';

export const INNOMOTICS_LIGHT_THEME = {
  name: 'innomotics-light',
  base: 'corporate',
  variables: {
    colorPrimary100: '#E1F000',
    colorPrimary200: '#08191F',
    colorPrimary300: '#FFFFFF',
    colorBasic100: '#E4EAED',
    colorBasic200: '#CAD5DA',
    colorBasic300: '#B2C1C7',
    colorBasic400: '#9AACB4',
    colorBasic500: '#657880',
    colorBasic600: '#53676F',
    colorBasic700: '#2A3B40',
    colorBasic800: '#2A3B40',
    colorBasic900: '#2A3B40',
    colorSuccess100: '#BAFFA9',
    colorSuccess200: '#01823D',
    colorWarning100: '#FFCC49',
    colorWarning200: '#FF853F',
    colorDanger100: '#FF9B8D',
    colorDanger200: '#CB0E0E',
    colorInfo500: '#1491EB',
    colorActive100: '#D2E100',
  },
} as NbJSThemeOptions;

export const INNOMOTICS_DARK_THEME = {
  name: 'innomotics-dark',
  base: 'corporate',
  variables: {
    colorPrimary100: '#E1F000',
    colorPrimary200: '#08191F',
    colorPrimary300: '#FFFFFF',
  },
} as NbJSThemeOptions;
