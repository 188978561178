import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ChartOptionsWrapper } from 'src/app/timeseries/model/chart-options.model';
import { chartOptionsActionTypes } from '../actions/chart-options.actions';

export const ChartOptionsFeatureKey = 'chartOptions';

export interface ChartOptionsState extends EntityState<ChartOptionsWrapper> {
  loading: boolean;
  error: string;
}

export const adapter: EntityAdapter<ChartOptionsWrapper> = createEntityAdapter<ChartOptionsWrapper>({
  selectId: (options) => options.chartId + options.propertyPath
});

export const initialState = adapter.getInitialState({
  loading: true,
  error: '',
});

export const reducer = createReducer(
  initialState,
  on(chartOptionsActionTypes.createChartOptions, (state, action) => {
    return {
      ...adapter.upsertOne(new ChartOptionsWrapper(
        action.chartId,
        undefined, // no property for default y1 axis...
        action.options, // use default options
      ), state),
      loading: false,
      error: '',
    };
  }),
  on(chartOptionsActionTypes.updateChartOptions, (state, action) => {
    return {
      ...adapter.updateOne(
        {
          id: action.chartId,
          changes: new ChartOptionsWrapper(
            action.chartId,
            action.propertyPath,
            action.options
          ),
        },
        state
      ),
      loading: false,
      error: '',
    };
  }),
  on(chartOptionsActionTypes.deleteChartOptionsForProperty, (state, action) => {
    return {
      ...adapter.removeOne(
        action.chartId + action.propertyPath, state),
      loading: false,
      error: '',
    };
  }),
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const isChartOptionsLoading = (state: ChartOptionsState): boolean => state.loading;
export const getError = (state: ChartOptionsState): string => state.error;
