import { AssetStateEffects } from './asset-state.effects';
import { AssetsEffects } from './assets.effects';
import { FeaturesEffects } from './feature.effects';
import { PropertiesEffects, SelectedAssetPropertiesEffects } from './properties.effects';
import { TimeEffects } from './time.effects';
import { TimeseriesEffects } from './timeseries.effects';

export const effects: any[] = [
  AssetsEffects,
  PropertiesEffects,
  SelectedAssetPropertiesEffects,
  TimeseriesEffects,
  TimeEffects,
  FeaturesEffects,
  AssetStateEffects
];
